import { Component, OnInit, ViewChild } from '@angular/core';
import { UnitsService } from 'src/app/services/units.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AddquotaComponent } from '../addquota/addquota.component';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { MainService } from 'src/app/services/main.service';
import { AddNoteComponent } from '../add-note/add-note.component';
import { UnitBlockComponent } from '../unit-block/unit-block.component';
import { ImportClientComponent } from '../import-client/import-client.component';
import { InvtaionComponent } from '../invtaion/invtaion.component';
import { AddCardComponent } from '../add-card/add-card.component';
import { ClientsService } from 'src/app/services/clients.service';
import { HttpClient } from '@angular/common/http';
import { VillageService } from 'src/app/services/village.service';
import { QrInvtationComponent } from '../qr-invtation/qr-invtation.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ThemePalette } from '@angular/material/core';
import * as _moment from 'moment';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { default as _rollupMoment } from 'moment';
import { ConfigurationsService } from 'src/app/services/configurations.service';
import { environment } from 'src/environments/environments';
import { RegisterAccessComponent } from '../register-access/register-access.component';
import { UnpaidInvoicesComponent } from '../unpaid-invoices/unpaid-invoices.component';
import { PrintConfirmationModalComponent } from '../print-confirmation-modal/print-confirmation-modal.component';
import { UnitUnpaidInvoicesComponent } from '../unit-unpaid-invoices/unit-unpaid-invoices.component';
import { tap } from 'rxjs/operators';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD / MM / YYYY',
  },
  display: {
    dateInput: 'DD / MM / YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const moment = _rollupMoment || _moment;

interface SelectProtected {
  readonly wrapperElement: HTMLDivElement | any;
}
@Component({
  selector: 'app-unit-details',
  templateUrl: './unit-details.component.html',
  styleUrls: ['./unit-details.component.css'],
  providers: [provideMomentDateAdapter(MY_FORMATS)],
})
export class UnitDetailsComponent implements OnInit {
  isMobile: boolean = false;

  page = 1;
  limit = 0;
  allConfiguration: any;
  frontPhoto: any;
  backPhoto: any;

  printingCardOrientation: any = environment.printingCardOrientation;

  showSpinner: boolean = true;
  unit_name: any;
  unitData: any;
  unitId: any;
  unit_clients: any = [];
  pipe = new DatePipe('en-US');
  mergeButtonStatus = false;
  mergeStatus = false;
  transferStatus = false;
  Priority: any;
  Quota: any;
  Generated: any;
  Used: any;
  Remaining: any;
  unitsTransferInput: any;
  dateCurrent: any;
  blockClientTitle = 'Block';
  cleintsKeys: any;
  cardObject = {
    name: '',
    unitName: '',
    validFrom: '',
    validTo: '',
    type: '',
    image: '',
    image1: 'assets/images/teamCard.jpg',
    frontImage: '',
    villageName: '',
    status: false,
    orientationPrinting: '',
  };
  unit_client_data: any = [];
  imageBase64: any;
  imageBase641: any;
  Filter_by = 'All';
  unitNameSearch: any;
  unitSearch_arr = [];

  mergeCounter: number = 0;
  virtualDataHolder: any[] = [];
  hintTextForMerging = '';

  replaceExtensionID: boolean = false;
  color: ThemePalette = 'primary';

  clientIdForInvoices: any;
  clientInvoices: any;

  clientIdArrayForPrinting: any;

  villageId: any;
  todayDate: any;
  @ViewChild('printModal') printModal!: PrintConfirmationModalComponent;

  constructor(
    private rest: UnitsService,
    public dialog: MatDialog,
    private _sanitizer: DomSanitizer,
    private mainService: MainService,
    private route: Router,
    private http: HttpClient,
    private villageService: VillageService,
    private clientService: ClientsService,
    private activeRouter: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private readonly configuration: ConfigurationsService
  ) {}

  ngOnInit() {
    this.showSpinner = true;

    this.http
      .get('/assets/images/fouka.jpg', { responseType: 'blob' })
      .subscribe((res: any) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          var base64data = reader.result;
          this.imageBase64 = base64data;
          this.unit_data();
        };
        reader.readAsDataURL(res);
      });
    this.http
      .get('/assets/images/foukaone.jpg', { responseType: 'blob' })
      .subscribe((res: any) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          var base64data = reader.result;
          this.imageBase641 = base64data;
        };
        reader.readAsDataURL(res);
      });

    this.dateCurrent = this.pipe.transform(new Date(), 'yyyy-MM-dd');
    this.unit_name = this.activeRouter.snapshot.paramMap.get('id');

    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.isMobile = result.matches;
      });

    this.getAllConfigurations();
    this.checkTodayDate();
  }

  goUnit(name: any) {
    location.href = `pages/unit_details/${name.name}`;
  }
  village_List(id?: any) {
    this.villageService.villageList().subscribe((res: any) => {
      res.results.forEach((element: any) => {
        if (element.id == id) {
          this.cardObject.villageName = element.name;
        }
      });
    });
  }

  checkTodayDate() {
    const currentDate = new Date();
    this.todayDate = this.pipe.transform(currentDate, 'yyyy-MM-dd');
  }

  unit_data() {
    this.unit_client_data = [];

    this.rest.unitDetails(this.unit_name).subscribe((res: any) => {
      this.showSpinner = false;
      this.unitData = res?.clients;
      this.villageId = res?.village_id;
      this.village_List(res?.village_id);
      if (res?.clients) {
        res.clients.unverified = res?.unverified;
        this.cleintsKeys = Object.keys(res?.clients);
        this.cleintsKeys = this.cleintsKeys.reverse();
        this.cleintsKeys.forEach((element: any) => {
          this.unit_client_data.push(...res?.clients[element]);

          if (localStorage.getItem('clientIdArrayForPrinting')) {
            this.clientIdArrayForPrinting = localStorage.getItem(
              'clientIdArrayForPrinting'
            );
            this.clientIdArrayForPrinting = JSON.parse(
              this.clientIdArrayForPrinting
            );

            setTimeout(() => {
              this.printModal.show();
            }, 1000);
          }
        });
      }
      this.unitId = res.id;
      this.getQuota(this.dateCurrent);
      this.unit_client_data.forEach((element: any) => {
        if (element.images.length != 0) {
          element.images.forEach((item: any) => {
            item.image = this._sanitizer.bypassSecurityTrustUrl(
              `data:image/png;base64,` + item.image
            );
            if (item.image_type == 'personal_photo') {
              element.photo = item.image;
            }
          });
        } else {
          element.images.push({
            image: 'assets/images/avatar.png',
          });
        }
      });
      this.unit_clients = this.unit_client_data;
    });
  }

  getAllConfigurations() {
    this.configuration
      .retrieveConfigurations(this.page, this.limit)
      .subscribe((data: any) => {
        this.allConfiguration = data.results;
        this.allConfiguration.forEach((element: any) => {
          if (element.images?.length != 0) {
            element.images?.forEach((item: any) => {
              if (item.image_type == 'front') {
                element.frontPhoto = item.image;
              } else if (item.image_type == 'back') {
                element.backPhoto = item.image;
              }
            });
          }
        });
      });
  }

  getUserType(value: any) {
    if (value == 'all') {
      this.unit_clients = this.unit_client_data;
    } else {
      this.unit_clients = this.unitData[value];
    }
  }

  showQuotaAvalible: any;
  getDateToGetQuota(event: any) {
    let date = this.pipe.transform(event.value, 'yyyy-MM-dd');
    this.getQuota(date);
  }

  getQuota(date: any) {
    this.rest.getQuota(this.unitId, date).subscribe((res: any) => {
      if (res) {
        this.Priority = res.priority;
        if (this.Priority == 1) {
          this.Priority = 'Override ALL';
        } else if (this.Priority == 2) {
          this.Priority = 'High Priority';
        } else if (this.Priority == 3) {
          this.Priority = 'Standard';
        } else {
          this.Priority = 'Low Priority';
        }
        this.Quota = res.quota;
        this.Generated = res.generated;
        this.Remaining = res.remaining;
        this.Used = res.used;
        this.showQuotaAvalible = true;
      } else {
        this.showQuotaAvalible = false;
      }
    });
  }

  add_quota() {
    const dialogRef = this.dialog.open(AddquotaComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        result.unit_id = this.unitId;
        this.rest.addQuota(result).subscribe((res: any) => {
          this.mainService.SuccessToast('Quota Added');
          this.dateCurrent = this.pipe.transform(new Date(), 'yyyy-MM-dd');
          this.getQuota(this.dateCurrent);
        });
      }
    });
  }

  addClient() {
    localStorage.setItem('unitName', this.unit_name);
    this.route.navigate(['pages/add_client', this.unitId]);
  }

  editClient(value: any) {
    this.route.navigate(['pages/edit_client', this.unitId, value]);
  }
  viewClient(value: any) {
    this.route.navigate(['pages/view_client', this.unitId, value]);
  }
  openToMerge() {
    this.mergeButtonStatus = !this.mergeButtonStatus;
    this.hintTextForMerging = 'Please select the NEW client';
  }

  getSelectedItem(item: any) {
    item.status = !item.status;

    if (item.status == true) {
      this.virtualDataHolder.push(item);
      this.mergeCounter = this.mergeCounter + 1;

      if (this.virtualDataHolder.length >= 1) {
        this.hintTextForMerging = 'Please select the OLD client';
      } else {
        this.hintTextForMerging = 'Please select the NEW client';
      }
    } else {
      this.virtualDataHolder = this.removeUserWithSameId(
        this.virtualDataHolder,
        item.id
      );
      if (this.virtualDataHolder.length >= 1) {
        this.hintTextForMerging = 'Please select the OLD client';
      } else {
        this.hintTextForMerging = 'Please select the NEW client';
      }
    }
  }

  removeUserWithSameId(userArray: any, idToRemove: any) {
    return userArray.filter((user: any) => user.id !== idToRemove);
  }

  client_merge_arr: any = [];
  clickToMerge() {
    let counter = 0;
    this.unit_clients.forEach((element: any) => {
      if (element.status == true) {
        counter = counter + 1;
      }
    });
    if (counter >= 2) {
      const button: any = document.getElementById('btn');
      button.click();
    }
  }

  mergeClients() {
    let dummy: any = [];
    this.unit_clients.forEach((element: any) => {
      if (element.status == true) {
        dummy.push(element);
      }
    });

    const unique = dummy.filter(
      (obj: any, index: any) =>
        dummy.findIndex((item: any) => item.name === obj.name) === index
    );

    for (let i = 0; i < unique.length; i++) {
      for (let j = 0; j < dummy.length; j++) {
        if (
          unique[i].name.toLowerCase() == dummy[j].name.toLowerCase() &&
          unique[i].id != dummy[j].id
        ) {
          this.rest
            .mergeClient(unique[i].id, dummy[j].id, this.replaceExtensionID)
            .subscribe((res: any) => {
              this.mainService.SuccessToast('Client Merged');
              this.unit_data();
            });
        }
      }
    }
  }

  transferUnitsOpen() {
    const button: any = document.getElementById('btn2');
    button.click();
  }

  transfer() {
    let data = {
      new_unit_id: this.newUnitId,
      old_unit_id: this.unitId,
    };
    this.rest.transferUnit(data).subscribe((res: any) => {
      this.mainService.SuccessToast('Unit Transfer Success');
      this.unit_data();
    });
  }
  newUnitId: any;
  allunits: any = [];
  showlistUnits = false;

  getUnits(data: any) {
    this.showlistUnits = true;
    this.rest.searchUnit(data.target.value).subscribe((res: any) => {
      this.allunits = res.results;
    });
    if (!data.target.value) {
      this.showlistUnits = false;
    }
  }

  original_units: any;
  showSpinnerForPermission: boolean = false;

  searchForUnits(value: any) {
    this.showSpinnerForPermission = true;
    this.rest.searchUnit(value).subscribe((res: any) => {
      this.allunits = res.results;
      this.showSpinnerForPermission = false;
    });
  }

  getUnit(item: any) {
    this.showlistUnits = false;
    this.unitsTransferInput = item.name;
    this.newUnitId = item.id;
  }

  clientIdDeleted: any;
  openDeleteClient(item: any) {
    this.clientIdDeleted = item.id;
    const button: any = document.getElementById('btn3');
    button.click();
  }

  deleteClient() {
    this.rest.deleteClient(this.clientIdDeleted).subscribe((res: any) => {
      this.mainService.SuccessToast('Client deleted');
      this.showSpinner = true;
      this.unit_data();
    });
  }

  addNote(value: any, id: any) {
    let idOfClientOrUnit;
    if (id) {
      idOfClientOrUnit = id;
    } else {
      idOfClientOrUnit = this.unitId;
    }
    const dialogRef = this.dialog.open(AddNoteComponent, {
      width: '450px',
      data: { id: idOfClientOrUnit, name: value },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (value == 'unit') {
          result.unit_id = this.unitId;
          (result.expiry_date = this.pipe.transform(new Date(), 'yyyy-MM-dd')),
            this.rest.addNoteForUnit(result).subscribe((res: any) => {
              this.mainService.SuccessToast('Note Added for the Unit');
            });
        } else {
          result.client_id = id;
          (result.expiry_date = this.pipe.transform(new Date(), 'yyyy-MM-dd')),
            this.rest.addNoteForClient(result).subscribe((res: any) => {
              this.unit_data();
              this.mainService.SuccessToast('Note Added for the client');
            });
        }
      }
    });
  }

  blockUnitGates(id: any) {
    let unitOrClientID: any;
    let unitOrClientName: any;
    if (id != null) {
      unitOrClientID = id;
      unitOrClientName = 'client';
    } else {
      unitOrClientID = this.unitId;
      unitOrClientName = 'unit';
    }
    const dialogRef = this.dialog.open(UnitBlockComponent, {
      width: '400px',
      data: { id: unitOrClientID, name: unitOrClientName },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (id != null) {
          let data = {
            gates: result,
            clients: [id],
          };
          if (result.balckList.length != 0) {
            data.gates = result.balckList;
            this.rest.blackListForClient(data).subscribe((res: any) => {
              this.mainService.SuccessToast('Client add in blackList Gates');
            });
          } else {
            data.gates = result.WhiteList;
            this.rest.whiteListForClient(data).subscribe((res: any) => {
              this.mainService.SuccessToast('Client add in whiteList Gates');
            });
          }
        } else {
          let data = {
            gates: result,
            units: [this.unitId],
          };
          if (result.balckList.length != 0) {
            data.gates = result.balckList;
            this.rest.blockUnitForGate(data).subscribe((res: any) => {
              this.mainService.SuccessToast('unit add in blackList Gates');
            });
          } else {
            data.gates = result.WhiteList;
            this.rest.whiteListGateUnit(data).subscribe((res: any) => {
              this.mainService.SuccessToast('unit add in whiteList Gates');
            });
          }
        }
      }
    });
  }

  importClient() {
    const dialogRef = this.dialog.open(ImportClientComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        let data = {
          unit_id: this.unitId,
          clients: result,
        };
        this.rest.importClient(data).subscribe((res: any) => {
          this.mainService.SuccessToast('Clients import');
          this.unit_data();
        });
      }
    });
  }

  openBlockClient(title: any, item: any) {
    if (title == 'block') {
      this.blockClientTitle = 'Block';
    } else {
      this.blockClientTitle = 'Active';
    }
    this.clientIdDeleted = item.id;
    const button: any = document.getElementById('btn4');
    button.click();
  }

  blockClient() {
    let data = {
      id: this.clientIdDeleted,
      cancelled: true,
    };
    if (this.blockClientTitle == 'Block') {
      data.cancelled = true;
    } else {
      data.cancelled = false;
    }
    this.clientService.editClient(data).subscribe((res: any) => {
      if (this.blockClientTitle == 'Block') {
        this.mainService.SuccessToast('Client Blocked');
      } else {
        this.mainService.SuccessToast('Client Active');
      }
      this.unit_data();
    });
  }

  generateInvitation(value: any) {
    const dialogRef = this.dialog.open(InvtaionComponent, {
      width: '450px',
      data: {
        unitName: this.unit_name,
        clientName: value.name,
        unitId: this.unitId,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        result.data.unit_id = this.unitId;
        result.data.client_id = value.id;
        result.data.valid_from = this.pipe.transform(
          result.data.valid_from,
          'yyyy-MM-dd hh:mm:ss'
        );
        result.data.valid_to = this.pipe.transform(
          result.data.valid_to,
          'yyyy-MM-dd hh:mm:ss'
        );

        result.data.valid_from = result.data.valid_from.replace(
          /\d{2}:\d{2}(:\d{2})?/,
          result.data.time_from + ':00'
        );

        result.data.valid_to = result.data.valid_to.replace(
          /\d{2}:\d{2}(:\d{2})?/,
          result.data.time_to + ':00'
        );

        delete result.data.time_from;
        delete result.data.time_to;

        if (result.value == 'normal') {
          this.rest.clientInvitation(result.data).subscribe(
            (res: any) => {
              if (res.status == false) {
                this.mainService.errorToast(res.message);
              } else {
                let data: any = result;
                data.qrName = res.qr_code;
                data.unitName = this.unit_name;
                (data.inviter = value.name), this.openQr(data);
              }
            },
            (err: any) => {
              this.mainService.errorToast('Something went error');
            }
          );
        } else {
          this.rest.clientSmsInvite(result.data).subscribe(
            (res: any) => {},
            (err: any) => {
              this.mainService.errorToast('Something went error');
            }
          );
        }
      }
    });
  }

  openQr(value: any) {
    const dialogRef = this.dialog.open(QrInvtationComponent, {
      width: '320px',
      data: value,
    });
  }

  addCard(value: any) {
    const dialogRef = this.dialog.open(AddCardComponent, {
      width: '450px',
      data: {
        id: value?.id,
        unitId: this.unitId,
        unitUsers: this.unit_client_data,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      const invoicesIdsToBeDelivered = result?.invoicesIdsToBeDelivered;

      if (result.data) {
        if (result.data.license_plate) {
          result.data.unit_id = this.unitId;
          result.data.client_id = value.id;
          this.rest
            .addRFId(result.data)
            .then((response) => response.text())
            .then((result) => {
              let res = JSON.parse(result);
              if (res.status == true) {
                invoicesIdsToBeDelivered.forEach((id: any) => {
                  this.invoiceDelivery(id);
                });

                this.mainService.SuccessToast('RFID Added');
              } else {
                this.mainService.errorToast('Opss something wrong happen!');
              }
            })
            .catch((error) => {
              this.mainService.errorToast('Opss something wrong happen!');
            });
        }
      } else {
        result.client_id = value.id;
        this.rest.addCard(result).subscribe((res: any) => {
          this.activeClient(value.id);
          this.mainService.SuccessToast('Card Added');
        });
      }
    });
  }

  dataPrint: any = [];
  userTypeForPrinting: any;
  orientationPrinting: any;

  checkUserType(type: any) {
    for (const obj of this.printingCardOrientation) {
      if (obj.userType === type) {
        this.orientationPrinting = obj.orientation;
        break;
      }
    }
  }

  logInvoicesId(): void {
    this.invoicesIdsToBeDeliveredForPrinting = this.checkInvoicesIds();
  }

  checkInvoicesIds() {
    const invoicesIds = localStorage.getItem('invoicesIds');
    return invoicesIds ? JSON.parse(invoicesIds) : [];
  }

  onModalOk() {
    this.logInvoicesId();
    if (this.clientIdArrayForPrinting) {
      this.clientIdArrayForPrinting.forEach((element: any) => {
        let userNotPrinted = this.unit_clients.filter(
          (item: any) => item?.id == element
        );
        if (userNotPrinted?.length) {
          this.clientService
            .printCardForClient(element, true)
            .subscribe((res: any) => {
              if (res.status) {
                this.mainService.SuccessToast('Printed success');
              } else {
                this.mainService.errorToast(res?.message);
              }
            });
        }
      });

      setTimeout(() => {
        this.invoicesIdsToBeDeliveredForPrinting.forEach((id: any) => {
          this.invoiceDelivery(id);
        });

        this.unit_data();
      }, 200);
      localStorage.removeItem('clientIdArrayForPrinting');
    }
  }

  onModalCancel() {
    if (this.clientIdArrayForPrinting) {
      this.clientIdArrayForPrinting.forEach((element: any) => {
        let userNotPrinted = this.unit_clients.filter(
          (item: any) => item?.id == element
        );

        if (userNotPrinted?.length) {
          this.clientService
            .printCardForClient(element, false)
            .subscribe((res: any) => {
              if (res.status) {
                this.mainService.infoToast('You did not print the card');
              } else {
                this.mainService.errorToast(res?.message);
              }
            });
        }
      });

      setTimeout(() => {
        this.unit_data();
      }, 200);
      localStorage.removeItem('clientIdArrayForPrinting');
    }
  }

  onModalBackground() {
    if (this.clientIdArrayForPrinting) {
      localStorage.removeItem('clientIdArrayForPrinting');
    }
  }

  invoiceDelivery(invoice: any) {
    this.clientService.deliverInvoice(invoice).subscribe({
      next: (response: any) => {
        this.mainService.SuccessToast('Invoice delivered');
      },
      error: (err) => {
        this.mainService.errorToast(err?.message);
      },
    });
  }

  printContent(contentId: string) {
    const printContent = document.getElementById(contentId);
    if (printContent) {
      const originalContents = document.body.innerHTML;
      const printContents = printContent.innerHTML;
      // document.body.innerHTML = printContents;

      document.body.innerHTML = `
      <html>
        <head>
          <title>Print</title>
          <style>
            .clientImage {
              width: 150px !important;
              height: 150px !important;
              object-fit: contain;
              margin-bottom: 30px;
              margin-top: 20px;
            }
          </style>
        </head>
        <body>
          ${printContents}
        </body>
      </html>
    `;

      // Wait for images to load before printing
      const images = Array.from(document.images);
      const loadedImages = images.map((img) => {
        if (img.complete) {
          return Promise.resolve(img);
        } else {
          return new Promise((resolve, reject) => {
            img.onload = () => resolve(img);
            img.onerror = reject;
          });
        }
      });

      Promise.all(loadedImages)
        .then(() => {
          window.onafterprint = () => {
            document.body.innerHTML = originalContents;
            window.location.reload();
          };
          window.print();
        })
        .catch(() => {
          console.error('Failed to load images for printing');
          document.body.innerHTML = originalContents;
          window.location.reload();
        });
    }
  }

  findPhotosByType(configurationArray: any, userType: any) {
    this.frontPhoto = null;
    this.backPhoto = null;

    for (const obj of configurationArray) {
      if (obj.type === userType) {
        this.frontPhoto = obj.frontPhoto;
        this.backPhoto = obj.backPhoto;
        break;
      }
    }
  }
  allOtherInvoices: any;
  unPaidOtherInvoices: any[] = [];
  paidOtherInvoices: any[] = [];
  userNotPrintedIds: any[] = [];
  invoicesIdsToBeDelivered: any[] = [];
  invoicesIdsToBeDeliveredForPrinting: any[] = [];

  clientId: any;
  clientsArrayWithCommonInvoiceForPrinting: any[] = [];

  getAllInvoices(data: any) {
    console.log(data);

    return this.rest.unit_invoice(this.unitId).pipe(
      tap((res: any) => {
        this.allOtherInvoices = res?.others;
        // this.unPaidOtherInvoices = this.allOtherInvoices.filter(
        //   (item: any) =>
        //     item?.purchase_name?.includes('Access') &&
        //     item?.paid === false &&
        //     item?.created_at?.includes(this.todayDate)
        // );

        const hasDetailsInvoices = this.allOtherInvoices.some(
          (invoice: any) => 'details' in invoice
        );
        if (hasDetailsInvoices) {
          this.unPaidOtherInvoices = this.allOtherInvoices.filter(
            (item: any) => {
              const details = item?.details || '';
              const numbers = details.match(/\d+/g)?.map(Number) || [];

              const setOfClientIds = new Set(this.userNotPrintedIds);
              const setOfNumbers = new Set(numbers);

              const commonIds = Array.from(setOfClientIds).filter((element) =>
                setOfNumbers.has(element)
              );

              if (
                // details.includes(this.accessType) &&
                (numbers.includes(this.clientId) || commonIds.length > 0) &&
                item?.updated_on?.includes(this.todayDate)
              ) {
                this.clientsArrayWithCommonInvoiceForPrinting =
                  this.unit_clients.filter((obj: any) =>
                    numbers.includes(obj.id)
                  );

                console.log(
                  'filtered',
                  this.clientsArrayWithCommonInvoiceForPrinting
                );

                return true;
              }
              return false;
            }
          );
        } else {
          console.log('mn badry');

          this.userNotPrintedIds = this.unit_clients
            .filter((item: any) => item?.printed === false)
            .map((item: any) => item.id);
          if (data == 'all') {
            this.openRegisterAccessDialog(data, this.userNotPrintedIds);
          } else {
            this.openRegisterAccessDialog(data);
          }
        }
      })
    );
  }

  print_card_check_invoices(data: any) {
    if (data == 'all') {
      // Getting the Ids of unprinted clients
      this.userNotPrintedIds = this.unit_clients
        .filter((item: any) => item?.printed === false)
        .map((item: any) => item.id);

      // Getting the Ids of unprinted clients
    } else {
      this.clientId = data.id;
    }

    this.getAllInvoices(data).subscribe({
      next: (response: any) => {
        if (this.unPaidOtherInvoices.length > 0) {
          console.log(this.unPaidOtherInvoices);

          // Filter out the invoices that are paid
          const unPaidOtherInvoices = this.unPaidOtherInvoices.filter(
            (invoice) => !invoice.paid
          );

          const paidOtherInvoices = this.unPaidOtherInvoices.filter(
            (invoice) => invoice.paid
          );

          if (this.unPaidOtherInvoices.some((invoice) => !invoice?.paid)) {
            console.log('false Only');

            // this.showSpinnerForRFID = true;

            const dialogRef = this.dialog.open(UnitUnpaidInvoicesComponent, {
              width: '700px',
              maxHeight: '700px',
              data: {
                unPaidOtherInvoices: unPaidOtherInvoices,
                unitId: this.unitId,
              },
            });

            dialogRef.afterClosed().subscribe((resultComeFromPayment: any) => {
              if (resultComeFromPayment?.status) {
                console.log('false Only w dafa3');

                // this.showSpinnerForRFID = false;

                if (data == 'all') {
                  let userNotPrinted = this.unit_clients.filter(
                    (item: any) => item?.printed === false
                  );

                  this.newPrintingScenario(userNotPrinted);
                } else {
                  this.newPrintingScenario(
                    this.clientsArrayWithCommonInvoiceForPrinting
                  );
                }
              } else {
                console.log('false Only w madfa3sh');

                this.mainService.errorToast('Invoice must be paid.');
                // this.invoicePaymentHint = 'Invoice must be paid.';
                // this.showInvoicePaymentHint = true;
              }
            });
          } else if (
            paidOtherInvoices.some(
              (invoice) => invoice?.paid && !invoice?.delivered
            )
          ) {
            this.invoicesIdsToBeDelivered = paidOtherInvoices.map(
              (invoice: any) => invoice.id
            );

            localStorage.setItem(
              'invoicesIds',
              JSON.stringify(this.invoicesIdsToBeDelivered)
            );

            console.log('TRUE FALSE');

            if (data == 'all') {
              console.log('TRUE FALSE print all');

              let userNotPrinted = this.unit_clients.filter(
                (item: any) => item?.printed === false
              );

              this.newPrintingScenario(userNotPrinted);
            } else {
              console.log('TRUE FALSE print client');
              this.newPrintingScenario(
                this.clientsArrayWithCommonInvoiceForPrinting
              );
            }
          } else if (
            paidOtherInvoices.some(
              (invoice) => invoice?.paid && invoice?.delivered
            )
          ) {
            console.log('TRUE TRUE');

            if (data == 'all') {
              console.log('TRUE TRUE register all');

              this.openRegisterAccessDialog(data, this.userNotPrintedIds);
              console.log(this.userNotPrintedIds);
            } else {
              console.log('TRUE TRUE register client');
              this.openRegisterAccessDialog(data);
            }
          }
        }
        if (this.unPaidOtherInvoices.length == 0) {
          console.log('mafiesh');

          if (data == 'all') {
            console.log('mafiesh register all');

            this.openRegisterAccessDialog(data, this.userNotPrintedIds);
            console.log(this.userNotPrintedIds);
          } else {
            console.log('mafiesh register client');

            this.openRegisterAccessDialog(data);
          }
        }

        //Fasel
        // if (this.unPaidOtherInvoices?.length) {
        //   const dialogRef = this.dialog.open(UnitUnpaidInvoicesComponent, {
        //     width: '700px',
        //     maxHeight: '700px',
        //     data: {
        //       unPaidOtherInvoices: this.unPaidOtherInvoices,
        //       unitId: this.unitId,
        //     },
        //   });

        //   dialogRef.afterClosed().subscribe((resultComeFromPayment: any) => {
        //     if (resultComeFromPayment?.status) {
        //       if (data == 'all') {
        //         let userNotPrinted = this.unit_clients.filter(
        //           (item: any) => item?.printed === false
        //         );
        //         this.newPrintingScenario(userNotPrinted);
        //       } else {
        //         this.newPrintingScenario([data]);
        //       }
        //     }
        //   });
        // } else {
        //   if (data == 'all') {
        //     let userNotPrinted = this.unit_clients.filter(
        //       (item: any) => item?.printed === false
        //     );
        //     this.newPrintingScenario(userNotPrinted);
        //   } else {
        //     this.newPrintingScenario([data]);
        //   }
        // }
      },
      error: (err) => {
        if (err?.status == 406) {
          // if (data == 'all') {
          //   let userNotPrinted = this.unit_clients.filter(
          //     (item: any) => item?.printed === false
          //   );
          //   this.newPrintingScenario(userNotPrinted);
          // } else {
          //   this.newPrintingScenario([data]);
          // }

          console.log('Mafiesh fawteeer khales');

          if (data == 'all') {
            console.log('Mafiesh fawteeer  register access all');

            this.openRegisterAccessDialog(data, this.userNotPrintedIds);
            console.log(this.userNotPrintedIds);
          } else {
            console.log('Mafiesh fawteeer  register access client');

            this.openRegisterAccessDialog(data);
          }
        }
      },
    });
  }

  openRegisterAccessDialog(data: any, userNotPrintedIds?: any) {
    const dialogRef = this.dialog.open(RegisterAccessComponent, {
      width: '450px',
      data: {
        unitId: this.unitId,
        clientId: this.clientId,
        userNotPrintedIds,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.rest.registerAccessService(result).subscribe((res: any) => {
          if (res.status == false) {
            this.mainService.errorToast(res?.message);
          } else {
            if (res.paid) {
              if (result.clients) {
                this.usersToBePrinted = this.filterArrayByIds(
                  this.unit_clients,
                  result.clients
                );
                this.newPrintingScenario([data]);
              } else if (!result.clients) {
                this.showSpinner = true;
                this.unit_data();
              }
            } else {
              const dialogRef = this.dialog.open(UnpaidInvoicesComponent, {
                width: '450px',
                data: {
                  result: res,
                },
              });

              dialogRef
                .afterClosed()
                .subscribe((resultComeFromPayment: any) => {
                  if (resultComeFromPayment?.status) {
                    if (result.clients) {
                      this.usersToBePrinted = this.filterArrayByIds(
                        this.unit_clients,
                        result.clients
                      );

                      this.newPrintingScenario([data]);
                    } else if (!result.clients) {
                      this.showSpinner = true;
                      this.unit_data();
                    }
                  } else {
                    this.showSpinner = true;
                    this.unit_data();
                  }
                });
            }
          }
        });
      }
    });
  }

  print_card_again(data: any) {
    const dialogRef = this.dialog.open(RegisterAccessComponent, {
      width: '450px',
      data: {
        unitId: this.unitId,
        clientId: data?.id,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.rest.registerAccessService(result).subscribe((res: any) => {
          if (res.status == false) {
            this.mainService.errorToast(res?.message);
          } else {
            if (res.paid) {
              if (result.clients) {
                this.usersToBePrinted = this.filterArrayByIds(
                  this.unit_clients,
                  result.clients
                );
                this.newPrintingScenario([data]);
              } else if (!result.clients) {
                this.showSpinner = true;
                this.unit_data();
              }
            } else {
              const dialogRef = this.dialog.open(UnpaidInvoicesComponent, {
                width: '450px',
                data: {
                  result: res,
                },
              });

              dialogRef
                .afterClosed()
                .subscribe((resultComeFromPayment: any) => {
                  if (resultComeFromPayment?.status) {
                    if (result.clients) {
                      this.usersToBePrinted = this.filterArrayByIds(
                        this.unit_clients,
                        result.clients
                      );

                      this.newPrintingScenario([data]);
                    } else if (!result.clients) {
                      this.showSpinner = true;
                      this.unit_data();
                    }
                  } else {
                    this.showSpinner = true;
                    this.unit_data();
                  }
                });
            }
          }
        });
      }
    });
  }

  activeClient(value: any) {
    let data = {
      id: value + '',
      activated: true,
    };
    this.clientService.editClient(data).subscribe((res: any) => {
      this.mainService.SuccessToast('Client Activated');
      this.unit_data();
    });
  }

  usersToBePrinted: any[] = [];
  registerAccess() {
    const dialogRef = this.dialog.open(RegisterAccessComponent, {
      width: '450px',
      data: {
        unitUsers: this.unit_client_data,
        unitId: this.unitId,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // this.showSpinner = true;
        if (result.valid_from) {
          result.valid_from = this.pipe.transform(
            result.valid_from,
            'yyyy-MM-dd hh:mm:ss'
          );
          result.valid_to = this.pipe.transform(
            result.valid_to,
            'yyyy-MM-dd hh:mm:ss'
          );

          result.valid_from = result.valid_from.replace(
            /\d{2}:\d{2}(:\d{2})?/,
            result.time_from + ':00'
          );

          result.valid_to = result.valid_to.replace(
            /\d{2}:\d{2}(:\d{2})?/,
            result.time_to + ':00'
          );
        } else {
          delete result.valid_from;
          delete result.valid_to;
        }

        delete result.time_from;
        delete result.time_to;

        if (result) {
          this.rest.registerAccessService(result).subscribe((res: any) => {
            if (res.status == false) {
              this.mainService.errorToast(res?.message);
              this.unit_data();
            } else {
              if (res.paid) {
                if (result.clients) {
                  this.usersToBePrinted = this.filterArrayByIds(
                    this.unit_clients,
                    result.clients
                  );
                  this.newPrintingScenario(this.usersToBePrinted);
                } else if (!result.clients) {
                  this.showSpinner = true;
                  this.unit_data();
                }
              } else {
                const dialogRef = this.dialog.open(UnpaidInvoicesComponent, {
                  width: '450px',
                  data: {
                    result: res,
                  },
                });

                dialogRef
                  .afterClosed()
                  .subscribe((resultComeFromPayment: any) => {
                    if (resultComeFromPayment?.status) {
                      if (result.clients) {
                        this.usersToBePrinted = this.filterArrayByIds(
                          this.unit_clients,
                          result.clients
                        );

                        this.newPrintingScenario(this.usersToBePrinted);
                      } else if (!result.clients) {
                        this.showSpinner = true;
                        this.unit_data();
                      }
                    } else {
                      this.showSpinner = true;
                      this.unit_data();
                    }
                  });
              }
            }
          });
        }
      }
    });
  }

  filterArrayByIds(array: any[], ids: number[]): any[] {
    return array.filter((item) => ids.includes(item.id));
  }

  activityLog() {
    this.route.navigate(['pages/activity_logs', this.unitId]);
  }

  invoices() {
    this.route.navigate(['pages/unit_invoice', this.unitId, this.unit_name]);
    localStorage.setItem('unitClients', JSON.stringify(this.unit_clients));
  }

  rentals() {
    this.route.navigate([
      'pages/unit_rentals',
      this.unitId,
      this.unit_name,
      this.villageId,
    ]);
  }
  qrCodes() {
    this.route.navigate(['pages/qr-codes', this.unitId, this.unit_name]);
  }
  goBack(): void {
    this.route.navigate(['pages/mange_units']);
  }

  newPrintingScenario(data: any) {
    data.forEach((element: any) => {
      // Pushing the ids of printing client in array in the LocalStorage
      let storedArray = localStorage.getItem('clientIdArrayForPrinting');
      let array: number[] = [];
      if (storedArray) {
        array = JSON.parse(storedArray);
      }
      array.push(element.id);
      localStorage.setItem('clientIdArrayForPrinting', JSON.stringify(array));
      // Pushing the ids of printing client in array in the LocalStorage

      let object_data: any = {
        name: element.name,
        unitName: this.unit_name,
        villageName: this.cardObject.villageName,
        image1: this.cardObject.image1,
        frontImage: '',
        userType: element.type.name,
        validFrom: this.pipe.transform(element.valid_from, 'dd-MM-yyyy'),
        validTo: this.pipe.transform(element.valid_to, 'dd-MM-yyyy'),
      };
      if (element.type.name == 'Main Owner' || element.type.name == 'Owner') {
        object_data.type = 'owner' + "'s" + ' ' + 'Card';
        object_data.status = true;
      } else {
        object_data.type = element.type.name + "'s" + ' ' + 'Card';
        object_data.status = false;
      }

      if (element.photo) {
        object_data.image = element.photo;
      } else {
        object_data.image = 'assets/images/avatar.png';
      }

      this.checkUserType(element?.type?.name);
      this.findPhotosByType(this.allConfiguration, element?.type?.name);
      object_data.image1 = this.backPhoto;
      object_data.frontImage = this.frontPhoto;
      object_data.orientationPrinting = this.orientationPrinting;

      this.dataPrint.push(object_data);
      // this.clientService
      //   .printCardForClient(element.id)
      //   .subscribe((res: any) => {});
    });
    setTimeout(() => {
      this.printContent('cardPrint');
    }, 200);
  }
}
